import { SET_KNOWLEDGELIST, SET_KNOWLEDGEL_BY_ID, STATUS_SUCCESS, STATUS_OK } from '@/constants'
import { getKnowledgeListService, getKnowledgeByIdService } from '@/services'

const knowledgeModule = {
  state: {
    knowledgeById: {},
    knowledgeList: [],
  },
  getters: {
    knowledgeList: (state) => state.knowledgeList,
    knowledgeById: (state) => state.knowledgeById
  },
  mutations: {
    [SET_KNOWLEDGELIST]: (state, payload) => {
      state.knowledgeList = payload
    },
    [SET_KNOWLEDGEL_BY_ID]: (state, payload) => {
      state.knowledgeById = payload
    }
  },
  actions: {
    getKnowledgeListApi: async ({ commit }, { limit, offset, from, to, type, order }) => {
      try {
        const resp = await getKnowledgeListService(limit, offset, from, to, type, order )
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_KNOWLEDGELIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getKnowledgeApi: async ({ commit }, { id }) => {
      try {
        const resp = await getKnowledgeByIdService(id )
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_KNOWLEDGEL_BY_ID, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export {
  knowledgeModule
}
