import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getNewsListService = async ( limit, offset, from, to, type, order ) => {
  try {
    return await client.get(apiConstant.KNOWLEDGE_LIST, {
      limit, offset, from, to, type, order
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getKnowledgeByIdService = async ( id) => {
  try {
    return await client.post(apiConstant.KNOWLEDGE_BY_ID, {
      id
    })
  } catch (error) {
    throw new Error(error)
  }
}


