export const SET_EMAIL = 'setEmail'
export const SET_TOKEN = 'setToken'
export const SET_ISMEMBER = 'setIsMember'
export const CLEAR_SESSION = 'clearSession'
export const SET_ERRMESSAGE = 'setErrMessage'
export const SET_ACTIVEERROR = 'setActiveError'
export const SET_ACTIVEINFO = 'setActiveInfo'
export const SET_INFOMESSAGE = 'setInfoMessage'
export const SET_NAME = 'setName'
export const SET_LOADING = 'setLoading'
export const REQUEST_PROFILE = 'requestProfile'
/* knowledge */
export const SET_KNOWLEDGELIST = 'setKnowledgeList'
export const SET_KNOWLEDGEL_BY_ID = 'setKnowledgeById'

/* news */
export const SET_NEWSLIST = 'setNewsList'
export const SET_NEWS_BY_ID = 'setNewsById'

/* FAQ */
export const SET_FAQLIST = 'setNewsList'
