export const STATUS_SUCCESS = 'success'
export const STATUS_OK = 'ok'

export const apiConstant = {
  REFRESH_TOKEN: '/refreshToken',
  LOGIN: '/api/v1/auth/login',
  REG_CREATE: '/registerCreateUser',
  REG_OTP: '/registerSendOTP',
  REG_VERIFY_OTP: '/registerVerifyOtp',
  PROFILE: '/user/profile',
  PROFIL_UPDATE: '/user/profile/update',
  USER_FORGET_PASS: '/user/forget_password',
  USER_VERIFY_OTP: '/user/verifyOTPPin',
  USER_NEW_PASSWORD: '/user/submitNewPassword',
  /* knowledge */
  KNOWLEDGE_LIST: '/api/v1/public/knowledge/getLastKnowledge',
  KNOWLEDGE_BY_ID: '/api/v1/public/knowledge/getKnowledgeById',

  /* FAQ */
  FAQ_LIST: '/api/v1/public/faq/getFAQs',
}
