import Vue from 'vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

/* custom */
import '@/styles/custom.css';
import '@/styles/loading.css';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: '',
  routes: configRoutes()
})

function configRoutes () {
  return [{
    path: '/',
    name: 'home',
    redirect: '/',
    component: () => import('@/pages/Main.vue'),
    children: [
      {
      path: '/',
      name: 'knowledge',
      meta: {
      },
      component: () => import('@/pages/knowledgeBase/knowledgeBase.vue')
      },
      {
        path: 'faq',
        name: 'faq',
        meta: {
        },
        component: () => import('@/pages/faq/faq.vue')
      },
      {
        path: 'news',
        name: 'news',
        meta: {
        },
        component: () => import('@/pages/news/news.vue')
      },
      {
        path: 'news/:id',
        name: 'news_detail',
        meta: {
        },
        component: () => import('@/pages/news/detail.vue')
      },
      {
        path: 'login',
        name: 'login',
        meta: {
        },
        component: () => import('@/pages/login/login.vue')
      },
      {
        path: 'register',
        name: 'register',
        meta: {
        },
        component: () => import('@/pages/register/register_step1.vue')
      },
      {
        path: 'cofirm_otp',
        name: 'cofirm_otp',
        meta: {
        },
        component: () => import('@/pages/register/register_step2.vue')
      },
      {
        path: 'member/profile',
        name: 'member/profile',
        meta: {
        },
        component: () => import('@/pages/profile/profile.vue')
      },
      {
        path: 'consultation/lists',
        name: 'consultation/lists',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationLists.vue')
      },
      {
        path: 'consultation/:id',
        name: 'member/detail',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationDetail.vue')
      },
      {
        path: '/consultation/create/step1',
        name: 'consultation/create/step1',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate1.vue')
      },
      {
        path: '/consultation/create/step2',
        name: 'consultation/create/step2',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate2.vue')
      },
      {
        path: '/consultation/create/step3',
        name: 'consultation/create/step3',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate3.vue')
      },
      {
        path: '/consultation/create/step4',
        name: 'consultation/create/step4',
        meta: {
        },
        component: () => import('@/pages/consultation/consultationCreate4.vue')
      }
    ]
  }]
}

router.beforeEach((to, from, next) => {
  next()
})

export default router
