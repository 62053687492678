import { SET_NEWSLIST, SET_NEWS_BY_ID, STATUS_SUCCESS, STATUS_OK } from '@/constants'
import { getNewsListService, getNewsByIdService } from '@/services'

const newsModule = {
  state: {
    newsById: {},
    newsList: [],
  },
  getters: {
    newsList: (state) => state.newsList,
    newsById: (state) => state.newsById
  },
  mutations: {
    [SET_NEWSLIST]: (state, payload) => {
      state.newsList = payload
    },
    [SET_NEWS_BY_ID]: (state, payload) => {
      state.newsById = payload
    }
  },
  actions: {
    getNewsListApi: async ({ commit }, { limit, offset, from, to, type, order }) => {
      try {
        const resp = await getNewsListService(limit, offset, from, to, type, order )
        if (resp.data.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_NEWSLIST, resp.data.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getNewsApi: async ({ commit }, { id }) => {
      try {
        const resp = await getNewsByIdService(id )
        if (resp.data.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_NEWS_BY_ID, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export {
  newsModule
}
